import React from 'react';
import styled from 'styled-components';

import DefaultImage from 'images/placeholders/default-image.jpg';
import SanitizeHtml from 'libs/SanitizeHtml';
import Video from 'parts/video/video';
import LazyImage from 'components/LazyImage';
import ImageWithPopUp from 'parts/image/ImageWithPopUp';

const GifImage = styled.img`
	object-fit: contain !important;
	position: relative !important;
	height: auto !important;
	max-width: 100% !important;
`;

/**
 * Represents a media component (either an image, video, svg or gif)
 * @param {object} props - The parameters for the component
 * @param {object} props.image - The image object
 * @param {object} props.video - The video object
 * @param {string} props.imagecrop - The image crop
 * @param {string} props.direction - The direction of the placements of the components in the parent component (horizontal or vertical)
 * @param {string} props.title - The title of the component
 * @param {string} props.to - The link to the component
 * @param {string} props.imagetext - The image text
 * @param {string} props.captionplacement - The placement of the image caption
 * @param {boolean} props.imagePopUps - The image popups, if true, the image will open in a popup
 * @param {object} props.spacing - The spacing of the component
 * @returns {React.ReactNode} The media part
 *
 */

export default function Media({
	image,
	video,
	imagecrop,
	direction,
	...props
}) {
	const imageUrl = image?.file?.url;
	const videoUrl = video?.file?.url;
	const svgContent = image?.svg?.originalContent;
	const isGif = image?.filetype === 'image/gif';

	/**
	 * If image is smaller than 768px, use the original size, else use 768px
	 */
	const imagewidth = image?.width < 768 ? image.width : 768;

	/**
	 * If image is smaller than 768px and imagecrop is not 16:10 and direction is "horizontal",
	 * use the original size, else use 480px.
	 */
	const imageheight =
		image?.height < 768 &&
		imagecrop !== '16:10' &&
		direction === 'horizontal'
			? image?.height
			: 480;

	/* Gif and no videofile */
	if (isGif && imageUrl && !videoUrl) {
		return (
			<GifImage
				src={imageUrl}
				alt={
					(!!props?.to && `Gå til ${props?.title}`) ||
					image?.title ||
					props?.title
				}
			/>
		);
	}

	/* SVG */
	if (svgContent) {
		return <SanitizeHtml html={svgContent} className="svg-image" />;
	}

	/* Video */
	if (videoUrl) {
		return (
			<Video
				file={{ file: { url: videoUrl } }}
				description={{
					childMarkdownRemark: {
						rawMarkdownBody: video?.description,
					},
				}}
				preview={image}
				spacing={{ bottom: 'none' }}
			/>
		);
	}

	if (imageUrl) {
		if (props?.imagePopUps) {
			return (
				<ImageWithPopUp
					image={{
						...image,
						width: imagewidth,
						height: imageheight,
					}}
					altText={image?.description}
					ariaLabel={image?.description}
					caption={image?.imagetext || props?.imagetext}
					componentSettings={{
						imagecrop:
							imagecrop ||
							(direction === 'vertical' && '16:10') ||
							'1:1',
						captionplacement: props?.captionplacement,
					}}
					spacing={{
						top: 'none',
						bottom: 'none',
					}}
				/>
			);
		} else {
			return (
				<LazyImage
					{...image}
					description={undefined}
					width={imagewidth}
					height={imageheight}
					ratio={
						imagecrop ||
						(direction === 'vertical' && '16:10') ||
						'1:1'
					}
					alt={
						(!!props?.to && `Gå til ${props?.title}`) ||
						image?.description ||
						image?.title ||
						props?.title
					}
					title={props?.title || image?.title}
					caption={props?.imagetext}
					settings={{
						captionplacement: props?.captionplacement,
					}}
				/>
			);
		}
	}

	return <img src={DefaultImage} alt={props?.title} />;
}
