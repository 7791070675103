import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';

import { usePopup } from 'context/PopupProvider';
import { ComponentWrapper } from 'components/ComponentWrapper';
import BlankButton from 'components/forms/BlankButton';
import LazyImage from 'components/LazyImage';
import MediumWidth from 'layouts/medium-width';

const Wrap = styled.div`
	max-width: 1200px;
	width: 100%;
	margin: 0 auto;
	overflow: hidden;
	position: relative;
	border-radius: ${p => p.theme.utils.borderRadius};
`;

const Inner = styled.div`
	text-align: center;

	svg {
		max-width: 100%;
	}

	.lazy-image {
		margin: 0 auto;
		img {
			margin: 0 auto;
		}
	}

	button {
		display: block;
		position: relative;

		&.image-button {
			margin: 0 auto !important;
			width: fit-content;
		}

		&:focus {
			outline: 0;
			.caption span {
				text-decoration: underline;
			}
		}
		> .lazy-image {
			width: fit-content;
		}
	}
`;

const MediumWidthWrapper = styled(MediumWidth)`
	max-width: ${p =>
		(p.width && p.theme.widths[p.width]) || p.theme.widths.medium};
	padding: 0;
	${p =>
		p.theme.media.smallOnly(css`
			padding: 0;
			margin: 0 -40px;
			.lazy-image,
			.lazy-image img {
				border-radius: 0 !important;
			}
		`)}
	> button {
		margin: 0;
		width: 100%;

		&:hover {
			.image-overlay {
				opacity: 1;
			}
			.image-overlay.has-caption::after {
				opacity: 0.75;
			}
		}
	}
`;

/**
 * Represents an image with a popup that shows a larger version of the image with a caption.
 * If the image is part of a gallery, the popup will show the other images in the gallery as well.
 * @param {object} image - The image source
 * @param {string} altText - The image alt text
 * @param {string} ariaLabel - The image aria-label
 * @param {string} caption - The image caption
 * @param {string} className - The image class name
 * @param {boolean} fullSizeClick - Whether the image should be clickable
 * @param {object} componentSettings - The component settings
 * @param {...object} props - Rest of the parameters for the component
 * @returns {JSX.Element} - The image with a popup
 **/
export default function ImageWithPopUp({
	image,
	altText,
	ariaLabel,
	caption,
	fullSizeClick = true,
	componentSettings,
	...props
}) {
	const {
		popupLinkRef,
		activePopup,
		setActivePopup,
		images,
		setImages,
		imageCount,
		setCurrentImage,
	} = usePopup();

	// Find the index of the current image in the images array
	const imageIndex =
		images?.findIndex(img => img?.file?.url === image?.file?.url) ?? 0;

	// Create a button title with the current image index and the total image count
	const buttonTitle =
		imageIndex !== -1 && imageCount > 1
			? ` (${imageIndex + 1}/${imageCount})`
			: '';

	// If the image is not in the images array, reset the images array
	useEffect(() => {
		const foundImage = images?.find(
			img => img?.file?.url === image?.file?.url
		);
		if (!foundImage) {
			setImages([]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [image]);

	if (!image?.file?.url) return null;

	return (
		<figure
			aria-label={ariaLabel || altText}
			className="component__image--img"
			id={`image-${getFileNameFromUrl(image?.file?.url)}`}>
			<ComponentWrapper {...props}>
				<Inner>
					{(fullSizeClick &&
						images?.length > 0 &&
						image?.file?.url && (
							<ClickableImage
								image={image}
								alt={altText}
								caption={caption}
								text={buttonTitle}
								count={imageCount}
								onClick={e => {
									e.preventDefault();
									popupLinkRef.current = e?.target;
									setCurrentImage(image?.file?.url);
									setActivePopup('image-slider');
								}}
								activePopup={activePopup}
								componentSettings={componentSettings}
							/>
						)) || (
						<Wrap>
							<LazyImage
								{...image}
								alt={altText || caption}
								ratio={componentSettings?.imagecrop || 'none'}
								caption={caption}
								clickable="false"
								settings={componentSettings}
							/>
						</Wrap>
					)}
				</Inner>
			</ComponentWrapper>
		</figure>
	);
}

/**
 * Represents a clickable image.
 * @param {object} image - The image source
 * @param {string} altText - The image alt text
 * @param {string} caption - The image caption
 * @param {string} text - The button text
 * @param {number} count - The total image count
 * @param {function} onClick - The click event handler
 * @param {string} activePopup - The active popup
 * @param {object} componentSettings - The component settings
 * @returns {JSX.Element} - The clickable image
 **/
function ClickableImage({
	image,
	alt,
	caption,
	text,
	count,
	onClick,
	activePopup,
	componentSettings,
}) {
	return (
		<MediumWidthWrapper
			width={image?.width > image?.height ? 'large' : 'medium'}>
			<BlankButton
				className="image-button"
				onClick={onClick}
				title={
					(count === 1 && 'Se større versjon av bildet' + text) ||
					'Se alle bilder' + text
				}
				aria-haspopup={true}
				aria-controls="image-slider"
				aria-expanded={activePopup === 'image-slider'}>
				<LazyImage
					{...image}
					alt={alt || caption}
					ratio={componentSettings?.imagecrop}
					count={count}
					text={text}
					caption={caption}
					clickable="true"
					settings={componentSettings}
				/>
			</BlankButton>
		</MediumWidthWrapper>
	);
}

/**
 * Gets the file name from a URL (without the file extension)
 * @param {string} url - The URL
 * @returns {string} - The file name
 **/
function getFileNameFromUrl(url) {
	if (!url) return;
	return url.split('/').pop().split('.').slice(0, -1).join('.');
}
