import React from 'react';
import styled, { css } from 'styled-components';

import { getComponentSettings } from 'libs/content';
import ImageWithPopUp from 'parts/image/ImageWithPopUp';
import MaxWidth from 'layouts/max-width';
import { AnimationWrap } from 'libs/animations';
import Spacing from 'layouts/Spacing';
import SanitizeHtml from 'libs/SanitizeHtml';

const Wrapper = styled(MaxWidth)`
	padding: 0;

	figure {
		.spacing {
			padding-bottom: 0;
		}

		img {
			border-radius: ${p => p.theme.utils.borderRadius};
			max-height: 100%;
			display: block;
		}

		svg {
			max-width: 100%;
		}

		${p =>
			p.$centered === 'true' &&
			css`
				text-align: center;
				.lazy-image img {
					margin: 0 auto;
				}
			`}
	}

	${p =>
		p.$width >= 1200 &&
		css`
			.component__image--img {
				max-width: 100vw;
				width: 100vw;
				position: relative;
				left: 50%;
				right: 50%;
				margin-left: -50vw;
				margin-right: -50vw;
				> figure {
					text-align: center;
					max-width: ${p => p.theme.widths.large};
				}
			}
		`}
`;

/**
 * Represents an image.
 * @param {object} image - The image source
 * @param {string} imagetext - The image description
 * @param {string} alt - The image alt text
 * @param {string} ariaLabel - The image aria-label
 * @param {string} headinglevel - The heading level for the title of the accordion
 * @param {...object} props - Rest of the parameters for the component
 * @returns {JSX.Element} - The image
 */
export default function Image({
	image,
	imagetext = '',
	alt = '',
	ariaLabel = '',
	...props
}) {
	if (!image?.file?.url && !image?.svg?.originalContent) return null;

	const componentSettings = getComponentSettings({
		settings: props?.settings,
		props: { ...props, imagetext },
	});

	return (
		<Spacing
			className="component__image"
			data-cy="component__image"
			{...props}>
			<Wrapper
				$width={image?.width}
				$centered={componentSettings?.centered}>
				<AnimationWrap
					doanimate={props?.transitions}
					direction="down"
					delay={1}>
					<div style={{ position: 'relative' }}>
						{(image?.svg?.originalContent && (
							<figure
								aria-label={ariaLabel || alt}
								className="component__image--svg">
								<SanitizeHtml
									html={image?.svg?.originalContent}
								/>
							</figure>
						)) || (
							<ImageWithPopUp
								image={image}
								altText={image?.description || ariaLabel || alt}
								ariaLabel={
									image?.description || ariaLabel || alt
								}
								caption={imagetext}
								componentSettings={componentSettings}
								spacing={{
									top: 'none',
									bottom: 'none',
								}}
							/>
						)}
					</div>
				</AnimationWrap>
			</Wrapper>
		</Spacing>
	);
}
